import styled from '@emotion/styled';
import { ArrowForward, FileDownload } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';

import { BackNavigation } from '../templates/BackNavigation';

export const Container = styled('div')({
    marginRight: 16,
    marginLeft: 16,
    // margin: 'auto',
    // '@media (min-width: 1200px)': {
    //     width: 1170,
    //     marginRight: 64,
    //     marginLeft: 64,
    //     margin: 'auto',
    // },
});

export const Header = styled('div')({
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 3,
});

export const H2 = styled('h2')({ color: ' #016db5', marginBottom: 10 });
export const Text = styled('p')({ color: ' #000000', fontSize: 14, lineHeight: 1.8 });
export const Ul = styled('ul')({ listStyle: 'outside', margin: 0, display: 'flex', flexDirection: 'column' });
export const Li = styled('li')({ color: '#000000', fontSize: 14, lineHeight: 1.5 });
export const Italic = styled('p')({ color: '#000000', fontSize: 14, lineHeight: 1.5, fontStyle: 'italic' });

export const Title = ({ style, children }: React.PropsWithChildren<React.HtmlHTMLAttributes<any>>) => {
    const Container = styled('div')({
        marginTop: 24,
        display: 'flex',
        alignItems: 'center',
        color: '#016db5',
        fontSize: 30,
    });
    return (
        <Container style={style}>
            <ArrowForward style={{ width: 50, height: 50 }} />
            {children}
        </Container>
    );
};

export const FormmationHeader = ({ children }: React.PropsWithChildren<React.HtmlHTMLAttributes<any>>) => {
    return (
        <Header>
            <BackNavigation>{children}</BackNavigation>
            <Title>{children}</Title>
        </Header>
    );
};

export const Body = styled('div')({ background: '#FFFFFF', display: 'flex', gap: 12 });

export const FormationContainer = styled('div')({ marginRight: 16, marginLeft: 16 });
export const FormationLayout = styled('div')({
    background: '#FFFFFF',
    display: 'flex',
    gap: 12,
    '@media (max-width: 800px)': {
        flexDirection: 'column',
    },
});
export const FormationProgramme = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 24,
});
export const FormationProgrammeSection = ({
    title,
    style,
    children,
}: React.PropsWithChildren<React.HtmlHTMLAttributes<any>>) => {
    return (
        <Accordion style={style}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ flexShrink: 0 }}>
                    <H2 style={{ fontSize: 16 }}>{title}</H2>
                </Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export const FormationContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 0,
    '@media (min-width: 800px)': { minWidth: 300, maxWidth: 300, paddingLeft: 16 },
});

export const FormationContentSection = ({
    title,
    style,
    children,
}: React.PropsWithChildren<React.HtmlHTMLAttributes<any>>) => {
    return (
        <>
            <H2 style={{ marginTop: 24 }}>{title}</H2>
            {children}
        </>
    );
};

export const BtnDownload = ({ href, style, children }: React.PropsWithChildren<React.AnchorHTMLAttributes<any>>) => {
    const Container = styled('a')({
        display: 'flex',
        alignItems: 'center',
        background: '#016db5',
        color: '#FFFFFF',
        marginTop: 12,
        borderRadius: 20,
        height: 40,
        //minWidth: 200,
        cursor: 'pointer',
        textTransform: 'uppercase',
        letterSpacing: 1,
        fontSize: 11,
        fontWeight: 'bold',
        padding: '0 12px',
        ':hover': { background: '#04273d' },
    });
    return (
        <Container style={style} href={href} target="_blank" rel="noopener noreferrer">
            <FileDownload style={{ width: 18, height: 18, marginRight: 5 }} />
            {children}
        </Container>
    );
};

export const Warning = ({ style, children }: React.PropsWithChildren<React.HtmlHTMLAttributes<any>>) => {
    const Container = styled('h6')({ color: '#000000', fontWeight: 600, lineHeight: 1.5 });
    const Content = styled('i')({ fontStyle: 'italic' });
    return (
        <Container style={style}>
            ATTENTION : <Content>{children}</Content>
        </Container>
    );
};
export const Underline = styled('span')({ textDecoration: 'underline' });
export const Strong = styled('strong')({ fontStyle: 'normal' });
export const Link = styled('a')({ textDecoration: 'none', cursor: 'pointer' });

export const Section = ({ title, style, children }: React.PropsWithChildren<React.HtmlHTMLAttributes<any>>) => {
    const Container = styled('div')({ display: 'flex', flexDirection: 'column', color: '#000000' });
    const Title = styled('strong')({ fontSize: 14, marginTop: 24 });

    return (
        <Container>
            <Title>{title}</Title>
            {children}
        </Container>
    );
};

export const FormationRate = ({ src }: React.ImgHTMLAttributes<any>) => {
    const Container = styled('div')({ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' });
    const Image = styled('img')({ width: '50%', '@media (max-width: 800px)': { width: '100%' } });
    const Link = styled('a')({ textDecoration: 'none' });
    return (
        <Container style={{ display: 'flex', justifyContent: 'left' }}>
            <Image src={src} />
            <Text>
                Les indicateurs sont calculés par les ministères chargés de la formation professionnelle et de
                l’éducation nationale et rendus publics.
                <br /> Lien :
                <Link
                    style={{ marginLeft: 4 }}
                    href="https://www.inserjeunes.education.gouv.fr/diffusion/accueil"
                    target="_blank"
                    rel="noopener noreferrer">
                    InserJeunes
                </Link>
                <Link
                    style={{ marginLeft: 4 }}
                    href="https://www.education.gouv.fr"
                    target="_blank"
                    rel="noopener noreferrer">
                    education.gouv.fr
                </Link>
            </Text>
        </Container>
    );
};

export const FormationTimeSection = () => (
    <FormationContentSection title="Horaire de formation">
        <Text>
            Lundi à vendredi
            <br /> de 8h – 12h et 13h – 16h
        </Text>
        <Text>
            <Underline>Bon à savoir :</Underline> Pour des besoins pédagogiques, certaines journées de formation
            pourront être délocalisées dans d’autres sites
        </Text>
    </FormationContentSection>
);

export const AccessibilitySection = () => (
    <FormationContentSection title="Accessibilité">
        <AccessibilityText />
    </FormationContentSection>
);

export const AccessibilityText = () => (
    <Text>
        Pour les personnes porteuses de handicap, nous vous invitons à contacter notre « Référent handicap ARCHADI
        Anfifa » pour vous accompagner dans vos différentes démarches pour l’accès à la formation au 02 69 61 21 25 ou
        contact@mifac-ofpca.fr
    </Text>
);

export const DateUpdate = () => {
    return <Text style={{ marginTop: 24 }}>Page mise à jour le 31 janvier 2025</Text>;
};

export const ButtonDownloadFormationMifac = () => (
    <BtnDownload href="/docs/formations/depliant_2024 _formation_mifac.pdf">Plaquette “Nos formations”</BtnDownload>
);

export const ButtonDownloadRGInterieurMifac = () => (
    <BtnDownload href="/docs/formations/rg_CFA-MIFAC.pdf">Règlement intérieur CFA-Mifac</BtnDownload>
);

export const SectionDelaisAccess = () => {
    return (
        <Section title="Délais d’accès">
            <Text>
                Pour s’inscrire à la formation, nous invitons les candidats à retirer le dossier d’inscription sur notre
                site internet{' '}
                <Link href="https://www.mifac-ofpca.fr" target="_blank">
                    <Underline>www.mifac-ofpca.fr</Underline>
                </Link>{' '}
                et à nous le déposer au centre de formation <Strong>ou</Strong> à nous le renvoyer par mail ci-après
                <Strong> contact@mifac-ofpca.fr.</Strong>
            </Text>
            <Strong>
                Les dossiers doivent être complétés dans leurs intégralités. Tout dossier incomplet ne sera pas examiné
                par nos services !
            </Strong>
            <Italic>
                <Strong>Nota : </Strong>
                Si le dossier d’inscription n’est pas disponible sur le site internet ou la date de dépôt mentionnée est
                échue, les candidats ne pourront plus déposer ou envoyer un dossier d’inscription. Toutefois, vous
                pouvez nous contacter pour plus d’information.
            </Italic>
        </Section>
    );
};
