import { Stack } from '@mui/material';

import { AppLayout } from '../../templates/AppLayout';
import {
    AccessibilitySection,
    BtnDownload,
    ButtonDownloadFormationMifac,
    ButtonDownloadRGInterieurMifac,
    DateUpdate,
    FormationContainer,
    FormationContent,
    FormationLayout,
    FormationProgramme,
    FormationProgrammeSection,
    FormationRate,
    FormationTimeSection,
    FormmationHeader,
    Li,
    Section,
    SectionDelaisAccess,
    Strong,
    Text,
    Ul,
    Underline,
} from '../FormationLayout';
import thumbnail_indicateur_DEJEPS from './thumbnail_indicateur_DEJEPS.png';

export default function DejepsPage() {
    return (
        <AppLayout>
            <FormationContainer>
                <FormmationHeader>DEJEPS – ASEC / Développement de projet, territoires et réseaux</FormmationHeader>
                <SubtitleDejeps />
                <FormationRate src={thumbnail_indicateur_DEJEPS} />
                <FormationLayout style={{ marginTop: 32 }}>
                    <FormationProgramme>
                        <FormationProgrammeSection title="Métier visé">
                            <Text>
                                La formation DEJEPS valide des compétences dans l’encadrement des activités éducatives,
                                sociales et culturelles dans la limite des cadres réglementaires.
                            </Text>
                            <Text>
                                Le diplôme permet à son titulaire de coordonner des projets de développement local en
                                autonomie dans diverses structures accueillant des publics variés allant de la petite
                                enfance au public adulte.
                            </Text>
                            <Text>
                                Il est le responsable de ses projets d’action du point de vue de leurs mises en œuvre,
                                de leurs mobilisations en ressources et de la sécurité des tiers et des personnes prises
                                en charge dans les projets activités.
                            </Text>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Exemples d’emplois préparés">
                            <Ul>
                                <Li>Animateur coordonnateur de projets</Li>
                                <Li>Adjoint de direction équipement socioéducatif</Li>
                                <Li>Responsable secteur enfant-jeunesse- éducation</Li>
                                <Li>Coordonnateur emploi, formation et insertion</Li>
                                <Li>Animateur socioéducatif ou territorial</Li>
                                <Li>Coordonnateur pôle famille (Référent famille)</Li>
                                <Li>Agent de développement local et vie associative</Li>
                            </Ul>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Secteur d’emploi">
                            <Text>
                                <Li>Structures d’animation, sportive et environnementale</Li>
                                <Li>Etablissements du lien social et familiale et/ ou médico-social </Li>
                                <Li>Organisations non gouvernementales</Li> <Li>Collectivités publiques</Li>{' '}
                                <Li>Entreprise du secteur marchand ou d’économie sociale et solidaire...</Li>
                            </Text>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Compétences">
                            <Ul>
                                <Li>
                                    Piloter la mise en œuvre de projets d’action sur un territoire dans le cadre des
                                    valeurs de l’éducation populaire et du développement durable
                                </Li>
                                <Li>
                                    Coordonner le travail en équipe, encourager le travail partenarial et accompagner
                                    l’implication des bénévoles dans la vie associative ou du projet
                                </Li>
                                <Li>
                                    Mettre en œuvre des démarches participatives à travers des actions de développement
                                    de compétences
                                </Li>
                                <Li>
                                    Organier la mobilisation des moyens (humains, financiers et matériels) tout en
                                    respectant les règles de sécurité des personnes impliquées dans le projet
                                </Li>
                                <Li>
                                    Favoriser l’élaboration d’une éthique citoyenne et engager un processus de
                                    construction d’une posture professionnelle de Coordonnateur de projets
                                </Li>
                            </Ul>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Objectifs & contenus">
                            <Text>
                                La formation s’appuie sur des stages en entreprise et s’articule autour de 3 blocs de
                                compétences qui intègrent 4 unités capitalisables (UC) et un module transversal dédié au
                                suivi des parcours formatifs des stagiaires. A l’issue de la formation, le stagiaire
                                sera capable de :
                            </Text>
                            <Section title="Bloc de compétence 1">
                                <Text>
                                    Etre capable de concevoir un projet d’action (UC1) et Coordonner sa mise en œuvre
                                    (UC2)
                                </Text>
                                <Ul>
                                    <Li>
                                        Contenus UC 1 : Méthodologie de projet – Diagnostic – Outils d’investigation et
                                        recueil de données – Sociologie des organisations
                                    </Li>
                                    <Li>
                                        Contenus UC 2 : Conduite et management de projet – Mobilisation des ressources
                                        (humaines, financières, logistiques) – Enjeux économiques, sociaux et culturels
                                    </Li>
                                </Ul>
                            </Section>

                            <Section title="Bloc de compétence 2">
                                <Text>
                                    Etre capable de conduire des démarches pédagogiques dans une perspective
                                    socioéducative (UC3).
                                </Text>
                                <Ul>
                                    <Li>
                                        Contenus UC3 : Pédagogie, courants et démarches pédagogiques – Pratiques et
                                        techniques d’intervention socioéducative – Construction d’un scénario et
                                        séquence de formation
                                    </Li>
                                </Ul>
                            </Section>

                            <Section title="Bloc de compétence 3">
                                <Text>Être capable d’animer en sécurité dans le champ d’activité (UC4).</Text>
                                <Ul>
                                    Contenus UC 4 : Notion étude de territoire – Enjeux de développement de projets
                                    territoriaux – Ressources juridiques et de prévention (responsabilité civile et
                                    pénale, réglementation ERP/ACM…)
                                </Ul>
                            </Section>

                            <Section title="Module transversal">
                                <Ul>
                                    <Li>
                                        Du positionnement à l’admission en formation – Progressions pédagogiques
                                        centre/entreprise ; déploiement du dispositif de certification ; bilans d’étape
                                        et projet professionnel…{' '}
                                    </Li>
                                </Ul>
                            </Section>

                            <Text>
                                <Underline>Bon à savoir :</Underline> Possibilité d’individualiser le parcours de
                                formation par la voie de certification indépendante des unités capitalisables.
                            </Text>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Publics concernés">
                            <Text>
                                La formation est ouverte à toute personne souhaitant s’inscrire dans une démarche de
                                professionnalisation en lien avec un projet professionnel dans la coordination et dans
                                l’encadrement de projet(s) d’action à finalité éducative, sociale ou d’insertion.
                            </Text>
                            <Text>
                                Sont ciblés par la formation : demandeurs d’emploi, salariés désirant se qualifier,
                                personnes en reconversion professionnel, salariés en contrat de professionnalisation,
                                volontaires en service civique, personnes inscrites à titre individuel.
                            </Text>

                            <Section title="Prérequis">
                                <Ul>
                                    <Li>Etre âgé de 18 ans révolus à la date d’entrée en formation</Li>
                                    <Li>
                                        Etre titulaire d’une des attestations suivante aux premiers secours (PSC1, AFPS,
                                        PSE, AFGSU, SST en cours de validité)
                                    </Li>
                                    <Li>
                                        Être titulaire d'un diplôme de niveau 4 dans le champ de l'animation ou du
                                        travail social inscrit au Répertoire National des Certifications
                                        Professionnelles
                                    </Li>
                                    <Li>
                                        Être titulaire d'un diplôme de niveau 6 inscrit au Répertoire National des
                                        Certifications Professionnelles
                                    </Li>
                                    <Li>
                                        Attester d'un niveau de formation correspondant à un niveau 4 et d'une
                                        expérience d'animation de 6 mois ou 400 heures en tant que bénévole ou
                                        professionnel
                                    </Li>
                                    <Li>
                                        Justifier de 24 mois d'activités professionnelles ou bénévoles correspondant à
                                        1600 heures minimum
                                    </Li>
                                </Ul>
                            </Section>

                            <Section title="Modalités d’accès">
                                <Ul>
                                    <Li>Satisfaire l’ensemble des épreuves de sélection</Li>
                                    <Li>
                                        Avoir un niveau de compréhension équivalent au bac et un projet professionnel
                                        orienté dans la coordination d’un projet d’action
                                    </Li>
                                    <Li>Avoir un casier judiciaire vierge (aucune condamnation-infraction pénale)</Li>
                                    <Li>
                                        Déposer un dossier de candidature complet (obligatoire) avec les pièces
                                        justificatives demandées avant la date limite des inscriptions
                                    </Li>
                                </Ul>
                            </Section>

                            <SectionDelaisAccess />

                            <Section title="Tests de sélection">
                                <Ul>
                                    <Li>
                                        Dossier de présentation du parcours professionnel de 3 à 4 pages joint avec le
                                        dossier d’inscription
                                    </Li>
                                    <Li>Epreuve écrite individuelle : étude de cas (4h)</Li>
                                    <Li>Epreuve orale : entretien de motivation (30 minutes)</Li>
                                </Ul>
                            </Section>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Méthodes mobilisées">
                            <Text>
                                La formation est construite à partir des méthodes d’éducation active alternant cours
                                théoriques, mises en situations professionnelles et réflexions collectives. Ici nous
                                nous appuyons sur :
                            </Text>

                            <Ul>
                                <Li>
                                    Les études cas et ateliers d’analyse de pratique, o Les travaux de groupe et travaux
                                    individuels guidés
                                </Li>
                                <Li>Les vécus expérientiels et les projets des apprenants</Li>
                                <Li>Les rencontres thématiques avec des professionnels</Li>
                                <Li>
                                    Les suivis individualisés à la production des écrits et dossiers de certification
                                </Li>
                                <Li>Les moments d’échange entre le stagiaire, le tuteur et le référent pédagogique</Li>
                            </Ul>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Ressources mobilisées">
                            <Section title="Moyens pédagogiques">
                                <Ul>
                                    <Li>
                                        Une salle de formation équipée des tables, chaises, tableaux blanc et vidéos
                                        projecteur
                                    </Li>
                                    <Li>
                                        Une salle dédiée à un espace de ressource-coworking avec un fond documentaire et
                                        d’information (ouvrages et revues) spécialisés dans le champ professionnel de
                                        l’animation
                                    </Li>
                                    <Li>
                                        Un fond de matériels techniques nécessaires pour les besoins en quantité et en
                                        qualité de la formation
                                    </Li>
                                    <Li>
                                        A proximité : un terrain de grand jeu, un plateau sportif polyvalent et des
                                        structures d’éducation populaire (MJC, MPT, etc…)
                                    </Li>
                                    <Li>
                                        Une équipe de formateurs et d’intervenants spécialisés dans diverses thématiques
                                        dispensées dans le cadre de la formation
                                    </Li>
                                    <Li>
                                        Un double accompagnement assuré par un(e) référent(e) pédagogique et un tuteur
                                        de stage pratique
                                    </Li>
                                </Ul>
                            </Section>
                            <Section title="Autres moyens">
                                <Ul>
                                    <Li> Des bureaux administratifs (accueil et direction)</Li>
                                    <Li> Un espace de convivialité et des restaurants à proximité</Li>
                                    <Li>Des sanitaires accessibles pour les personnes à mobilité réduite</Li>
                                    <Li>Possibilité de stationnement gratuit aux environs du centre de formation</Li>
                                    <Li>
                                        Accès wifi sécurisé, imprimantes et d’autres matériels à disposition des
                                        apprenants
                                    </Li>
                                </Ul>
                            </Section>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Modalités d’évaluation">
                            <Section title="Conditions d’obtention du diplôme">
                                <Text>
                                    Le DEJEPS - ASEC /DPTR s’obtient par la capitalisation des 4 unités capitalisables.
                                    Il se fait de manière ponctuelle, c’est-à-dire pas d’examen final, mais avec un
                                    processus de certification en centre et en entreprise échelonné sur la durée du
                                    parcours de formation.
                                </Text>
                                <Text>
                                    Un programme de rattrapage est prévu en cas de non satisfaction à la certification
                                    initiale, soit après quelques semaines, soit au cours d’une session de rattrapage
                                    mise en place par l’organisme.
                                </Text>
                                <Text>
                                    Des validations partielles sont par ailleurs possibles pour les stagiaires n’ayant
                                    pas obtenus le diplôme afin de les permettre de garder le bénéfice des unités
                                    capitalisables en vue d’une éventuelle réinscription à la formation.
                                </Text>
                            </Section>
                            <Section title="Conditions de certification du diplôme">
                                <Text>
                                    Celles-ci sont mentionnées dans l’arrêté du 20 octobre 2017 modifiant l’arrêté du 20
                                    novembre 2006 portant organisation du DEJEPS / ASEC délivré par le ministère chargé
                                    de la jeunesse et sport.
                                </Text>
                                <Text>
                                    Les unités capitalisables constitutives de la mention « développement de projets,
                                    territoires et réseaux » sont attribuées selon le référentiel de certification dont
                                    l’acquisition est contrôlée par les épreuves certificatives suivante : « production
                                    de documents écrits personnel ; entretien oral ; mise en situation professionnelle
                                    ».
                                </Text>
                            </Section>
                            <Section title="Validation des acquis de l’expérience (VAE)">
                                <Text>
                                    Pour le moment, l’organisme de formation ne propose pas un accompagnement VAE.
                                    Toutefois, une démarche de réflexion est cours d’étude pour sa faisabilité
                                    opérationnelle.
                                </Text>
                            </Section>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Financement de la formation">
                            <Section title="Comment financer votre formation ?">
                                <Text>
                                    Diverses sources de financement sont possibles pour le DEJEPS/ASEC - DPTR. Elles
                                    dépendent de votre statut de candidat et peuvent-être prises en charge dans le cadre
                                    :
                                </Text>
                                <Ul>
                                    <Li>D’un financement à votre propre initiative</Li>
                                    <Li>D’un financement de votre Organisme collecteur (AKTO Mayotte)</Li>
                                    <Li>D’un plan de formation de votre employeur</Li>
                                    <Li>De votre Compte Personnel de Formation (CPF)</Li>
                                    <Li>Du plan de développement de compétences (PDC)</Li>
                                    <Li>
                                        D’un financement de votre Conseil départemental. A ce titre, 12 parcours sont
                                        prises en charge gratuitement pour les demandeurs d’emploi
                                    </Li>
                                </Ul>
                            </Section>
                            <Section title="Tarif de la formation">
                                <Text>
                                    Pour connaitre le détail de nos tarifs, veillez nous contacter au 02 69 61 21 25 ou
                                    nous écrire au contact@mifac-ofpca.fr Un devis détaillant les tarifs appliqués par
                                    unité capitalisable vous sera par ailleurs proposé.
                                </Text>
                            </Section>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Poursuite de parcours">
                            <Text>
                                L’obtention du diplôme DEJEPS - ASEC/DPTR vous donne l’opportunité d’être opérationnel
                                en tant que coordonnateur de projet et/ou d’entreprendre une formation supérieure telle
                                que le DESJEPS dans les métiers de la direction de structure ou de projet.
                            </Text>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Rythme & durée de la formation">
                            <Text>1900 heures sont prévues pour la formation DEJEPS – ASEC/DPTR :</Text>
                            <Ul>
                                <Li> 700 heures d’enseignement théorique en centre de formation</Li>
                                <Li>
                                    1200 heures de pratique professionnelle en entreprise (congés ou vacances compris)
                                </Li>
                                <Li>Pour une durée de 16 mois de formation</Li>
                                <Li>Avec une alternance de 1 à 2 semaines en centre et 3 à 4 semaines en entreprise</Li>
                                <Li>Et un rythme de 35 heures hebdomadaire en centre et en entreprise</Li>
                            </Ul>
                            <Text>
                                Les heures liées aux épreuves de sélection ainsi que celles du positionnement ne sont
                                pas comprises dans le volume horaire total de la formation.
                            </Text>

                            <Section title="Pour votre information :">
                                <Ul>
                                    <Li>
                                        En cas de pertes d’heures de formation pour cas de force majeur et ne relevant
                                        pas de notre responsabilité, nous nous réservons la possibilité de les rattraper
                                    </Li>
                                    <Li>
                                        Compte tenu du contexte local et des besoins de renforcer les compétences des
                                        apprenants, une plateforme FOAD (e-learning) sera mise en place pour proposer en
                                        complément des cours en présentiel d’autres modalités et méthodes d’enseignement
                                        supplémentaire
                                    </Li>
                                </Ul>
                            </Section>
                        </FormationProgrammeSection>
                    </FormationProgramme>

                    <FormationContent>
                        <BtnDownload href="/docs/formations/dejeps/fiche_programme_DEJEPS-ASEC_DPTR.pdf">
                            Fiche programme DEJEPS - ASEC/DPTR
                        </BtnDownload>
                        <BtnDownload href="/docs/formations/dejeps/dossier_inscription_dejeps.pdf">
                            Dossier d’inscription 2024-2026
                        </BtnDownload>
                        <BtnDownload href="/docs/formations/dejeps/calendrier-dejeps_asec-dptr.pdf">
                            Calendrier prévisionnel 2024-2026
                        </BtnDownload>
                        <BtnDownload href="/docs/formations/dejeps/plaquette_dejeps-asec-dptr.pdf">
                            Plaquette DEJEPS - ASEC/DPTR
                        </BtnDownload>
                        <BtnDownload href="/docs/formations/dejeps/tableaux_equivalence-dejeps-AsecDptr.pdf">
                            Tableaux équivalence DEJEPS - ASEC/DPTR
                        </BtnDownload>

                        <ButtonDownloadFormationMifac />
                        <ButtonDownloadRGInterieurMifac />

                        <FormationTimeSection />
                        <AccessibilitySection />
                        <DateUpdate />
                    </FormationContent>
                </FormationLayout>
            </FormationContainer>
        </AppLayout>
    );
}
export const SubtitleDejeps = () => {
    return (
        <Stack style={{ marginTop: 24 }}>
            <Strong>Diplôme d’Etat de la Jeunesse, de l’Education Populaire et du Sport (DEJEPS),</Strong>
            <Text style={{ lineHeight: '0px' }}>
                Spécialité Animation Socioéducative et Culturelle (ASEC) - Mention Développement de Projets, Territoires
                et Réseaux (DPTR)
            </Text>
            <Text style={{ lineHeight: '0px', marginTop: 16 }}>
                <Underline>Certificateur :</Underline> Ministère des Sports et des Jeux olympiques et paralympiques
            </Text>
            <Text style={{ lineHeight: '0px', marginTop: 16 }}>Code RNCP 4900 --- Enregistré le 22-11-2006 </Text>
        </Stack>
    );
};
